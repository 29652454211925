.specials {
	margin-top: 61px;

	&__main-title {
		text-align: center;
	}

	&__content {
		padding: 15px;
		background: #ffffff;
	}

	&__title-container {
		@media (min-width: 768px) {
			height: 5rem;
			overflow: hidden;
			position: relative;
		}

		@media (min-width: 1000px) {
			height: inherit;
		}
	}

	&__title {
		margin-top: 0.5rem;

		@media (min-width: 768px) {
			position: absolute;
			bottom: 1rem;
			width: 100%;
			margin-bottom: 0;
		}

		@media (min-width: 1000px) {
			position: static;
			margin-bottom: 1rem;
		}
	}

	&__image {
		max-width: 100%;
		height: auto;
		display: block;
	}

	&__text {
		margin-bottom: 2rem;

		@media (min-width: 768px) {
			height: 2.75rem;
			overflow: hidden;
		}

		@media (min-width: 1000px) {
			height: 3.125rem;
		}
	}

	&__button-container {
		text-align: right;

		.button {
			text-align: center;
			font-size: 1.1rem;
			padding: 0.8rem 1.5rem;
		}
	}
}