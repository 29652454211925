// LAYOUT //

html {
	// font-size:24px;
}

body {
	font-family:'FrutigerNeue1450W01-Boo_1196290', Arial;
	background:url('../img/background-pattern.jpg');
	margin-top:-1px;
	padding-top:1px;
	color:@color-dark;
}


// HEADER //

header {
	background:@color-red;
	width:100%;
	.clearfix();
	overflow: visible;
	box-sizing: border-box;
	position: absolute;
	z-index: 5;
	.transition(color 0.5s);

	.site-home & {
		background:@color-red-rgba;
		&:hover {
			background:@color-red;
		}
	}

	&:hover {
		background:@color-red;
	}

	nav {
		max-width:1200px;
		margin:0 auto;
	}

	h1 {
		margin:0;
		padding: 0.5rem 0 0.5rem 2rem;

		&.image {
			line-height: 0;
			float:left;
			display: inline-block;

			img {
				height:@logo-height;
			}
		}

	}

	ul {
		margin:0;
		.clearfix();
		overflow: visible;

		li {
			float:left;
			padding:1rem 0 1rem 0;
			margin-right:1.7rem;
			line-height: 56px;

			&.last {
				margin-right:0;
			}
		}
	}
}


// MAINNAVIGATION //

.main-navigation, .meta-navigation {
	a {
		&:hover {
			color:darken(white,30%);
		}
	}
}

.main-navigation {
	float:left;
	display:inline-block;
	.clearfix();
	font-size:@font-navigation;
}

.meta-navigation {
	float:right;
	display:inline-block;
	.clearfix();
	overflow: visible;
	font-size:@font-ultramini;

	.icon-navigation {
		border-left:1px solid rgba(255,255,255,0.2);
		margin:0;
		padding:0;
		line-height: 56px;
		font-size: 1.5rem;

		a {
			display: inline-block;
			padding:1rem 1.5rem;

			i {
				.transition(all 0.3s);
			}

			&:hover {
				background:lighten(@color-red, 5%);
				color:white;

				i, span {
					.opacity(1);
				}
			}
		}

		span {
			line-height: 56px;
			color: #ffffff;
			.opacity(0.75);
			display: block;
			padding: 1rem 1.5rem;
			font-size: 12px;

			i {
				color: #ffffff;
				width: auto;
			}

			.dropdown.icon:before {
				content: "\f0d7";
			}
		}

		&:hover {
			span {
				background: lighten(@color-red, 5%);
				color: white;
				.opacity(1);

				i {
					.opacity(1);
				}
			}
		}
	}
	.ui.dropdown {
		.menu {
			.border-radius(0);
			padding: 0;
			margin: 0;

			li {
				text-align: center;
				display: block;
				width: 100%;
				padding: 0;
				font-size: 12px;
			}
		}

		&.selection {
			border-radius: 0 !important;
			line-height: 72px;
			padding-bottom: 0.67em;
			border: none !important;
			transition: none !important;
			-webkit-box-shadow: none !important;
			box-shadow: none !important;
			color: #ffffff;
			.opacity(0.75);
			font-size: 12px;
			background: transparent;

			&:hover {
				background:lighten(@color-red, 5%);
				color:white;
				.opacity(1);
			}

			.menu {
				-webkit-box-shadow: none !important;
				box-shadow: none !important;
				border-radius: none;
				left: -1px;
			}
			&:hover {
				-webkit-box-shadow: none !important;
				box-shadow: none !important;
			}
		}
	}
}

.ui.active.selection.dropdown,
.ui.selection.dropdown,
.ui.selection.dropdown .menu {
	border-radius: 0 !important;
}


// MOBILE NAVIGATION //

.mobile-navigation {
	position: fixed;
	width: 100px;
	background: @color-red;
	right:0;
	// height:100%;
	z-index: 150;
	display: none;

	ul {
		list-style: none;
		padding:0;
		margin:0;
		li {
			float: left;
			width: 50%;
			font-size:1.5rem;
			text-align: center;
			height: 61px;
			line-height: 61px;

			a {
				display: block;
				height:100%;
				width:100%;
				&:hover {
					background:lighten(@color-red, 5%);
					color:white;
				}
			}
		}
	}
}

// MENU NAVIGATION
.overlay.navigation {

}
.overlay.navigation nav {
	ul {
		padding: 0px;
		margin: 0px;
	}
	li {
		a {
			display: block;
			box-sizing: border-box;
			width: 100%;
			padding: 0.5em 1em 0.5em 1em;
			border-bottom: 1px solid grey;
		}
	}

}


// SECTIONS //

section {
	// border-bottom:5px solid @color-green;
}

.contentfirst {
	padding:5rem 0;
}

.content {
	padding:0 0 5rem 0 ;

	&.pager {
		text-align: center;
		.clearfix();
	}

	&.meta-bar {
		padding:0;
	}
}


.meta-bar {

	margin-bottom:5rem;
	ul {
		margin:0;
		padding:0;

		li {
			float:left;

			&.active a {
				background:@color-grey-active;
				color:white;
			}

			&:first-child {
				a {
					border-left: none;
				}
			}
		}


		a {
			background:white;
			display: inline-block;
			padding:0.4rem 0.7rem;
			color:@color-dark;
			border-left: 1px solid #aaaaaa;

			&:hover {
				background:lighten(@color-grey-active,30%);
			}
		}


	}
}

.pager {
	ul {
		li {
			a {
				padding:0.2rem 0.7rem;
				background:white;
				display: inline-block;
			}
		}
	}

	ul {
		.clearfix();
		width:auto;
		display: inline-block;

		li {
			float:left;
			margin:0;

			&.active {
				a {
					background:@color-grey-active;
					color:white;

					&:hover {
						background:@color-grey-active;
					}
				}
			}

			a {
				border-right:1px solid @color-grey-active;
				color:@color-dark;

				&:hover {
					background:lighten(@color-grey-active, 30%);
				}
			}

			&.first-entry {
				margin-right:2rem;

				a {
					border-right:none;
				}
			}

			&.last-entry {
				margin-right:0;
				margin-left:2rem;
			}

			&.last {
				a {
					border-right:none;
				}
			}
			&.more-first {
				margin-right:0.5em;
			}

			&.more-last {
				margin-left:0.5em;
			}
		}
	}
}

.full-image {
	height:500px;
	width:100%;
}
.frontpage-layer {
	top:0px;
	position: relative;
	height: 850px;

	&.absolute {
		position: absolute;
		width: 100%;
	}
	&:not(.slick-initialized){
		.imageslide{
			display: none;
			&:first-child {
				display:block;
			}
		}
	}
}

.frontpage-cta {
	position: relative;
	bottom:-47%;
}

.frontpage-image {
	margin-top: @header-height - 20px;
	background:url('../img/frontslider/Slide_1.jpg') center top;
	height: 500px;
	background-size: cover;
}


// SLIDER //

.slider {
	width:100%;


	/* Frontpage */

	&.frontpage {
		height:850px;
		text-align: center;

		.imageslide {
			height:850px;
		}
	}
}
// MAINPAGE STOERER
.frontpage-stoerer-left {
	position: absolute;
	top: @header-height-full;
	height: 300px;
	//border: 1px solid green;
	width: 300px;

	a {
		color: white;
		width: 250%;
		display: block;
		text-align: center;
		//background: #739228;
		background: #FF00FF;
		transform: translate(-29%,100%) rotate(-45deg);
		transform-origin: 50% 100%;
		top: 50%;
		line-height: 1.5rem;
		padding: 1rem;
		box-sizing: border-box;
	}
	.singleline {
		padding: 2rem;
	}
	.firstline, {

	}
	.secondline {
		font-size: 1.5em;
		font-weight: bold;
	}
}

// MAINPAGE WIDGET //

.widget {
	// background:white;
	// height:50px;
	margin:0 auto;
	position: relative;
	display: inline-block;
	width:auto;
	text-align: center;
	.box-sizing(border-box);

	&.object-compact {

		.widget-container.floatright {
			float:left !important;
		}
	}

	&.front {
		top:70%;
	}

	.order-widget & {
		position:static;
		width:100%;
		padding-left:2rem;
	}

	.widget-container {
		float:left;
		line-height: 1;
		// background:white;
	}

	.ui {

		&.selection {
			&.dropdown {
				min-width:120px;
				.border-radius(0) !important;
				line-height: 1;
				border: none;
				.box-shadow(none) !important;
				text-align: left;
				padding: 1px 1em 2px;
				border-right:1px solid rgba(0,0,0,0.1);
				line-height: 42px;


				&.active {
					.border-radius(none) !important;
				}

				&.first {
					border-left:1px solid rgba(0,0,0,0.1)
				}

				.dropdown.icon {
					position: absolute;
					right:1em;
					top:12px;
                    border: none !important;
				}


				&:hover {
					.icon {
						.opacity(1);
					}
				}

			}
		}

		&.input {

			input {
				border:none;
				border-right:1px solid rgba(0,0,0,0.1);
				//border-top:1px solid rgba(0,0,0,0.1);
				border-bottom:none;
			  	padding-top: 16px;
			  	padding-bottom: 17px;
			  	line-height: 42px;
			  	height: 45px;
			}

			&.first {
				input {
					border-left: 1px solid rgba(0,0,0,0.1) !important;
				}
			}

		}

	}

	.ui.icon.input > .icon.calendar {
		padding-top:15px;
	}


	// WIDGET OBJECT DETAIL //

	&.object-detail {

		width:100%;

		.widget-container {
			width:50%;
			margin-bottom:2rem;

			.checkout & {
				width:100%;
				border-left:1px solid rgba(0,0,0,0.1);
			}


			&.first {
				margin-top:2rem;
			}
			.submit {
				margin-right: 0rem;
			}
		}

		.dropdown {
			width:100%;
			border-top:1px solid rgba(0,0,0,0.1) !important;
			border-bottom:1px solid rgba(0,0,0,0.1) !important;
		}

		.ui.icon.input {
			width:100%;
				border-bottom:1px solid rgba(0,0,0,0.1) !important;
				border-top:1px solid rgba(0,0,0,0.1) !important;
			input {
				width:100%;

			}
		}

		&.checkout {
			float:left;
			text-align: left;
			width:100%;
		}

	}




	input[type=text] {
		padding:0.60em 1em;
	}


}

@-moz-document url-prefix() {
	.widget .ui.input input {
		padding-top: 12px;
		padding-bottom: 14px;
	}
}


.widget-additional-input {
	width:100%;
	position: absolute;
	bottom:50px;
	background:white;
	text-align: left;
  	z-index: 100;

	.ui.selection.dropdown {
		border:1px solid rgba(0,0,0,0.1);
		margin-bottom:1rem;
	  	width: 87%;
	}

	&.hide {
		display:none;
	}

	.object-compact & {
		bottom:auto;
		top:140px;
		width:97%;

		.filter-row {
			max-width:@max-width;
		}
	}
}

#objNumber{
	width: 87%;
	height: 26px;
	border: 1px solid rgba(0, 0, 0, 0.1);
}

// PRICE SLIDER //

#minPriceLabel {
	display:block;
	padding:2rem 0;
	float:left;
}

#maxPriceLabel {
	display:block;
	padding:2rem 0;
	float:right;
}

.noUi-handle {
	cursor: pointer;
}

.noUi-connect {
  background: @color-red;
}

.show-additional-input {
	display: inline-block;
	line-height: 42px;
	padding: 1px 1rem 2px;
	background:white;
	color:@color-grey-light;

	&:hover {
		color:@color-red;
	}
}

.filter-section {
	width:25%;
	box-sizing: border-box;
	padding:2.5%;
	height:100%;
	float:left;

	&.width-25 {
		width:25%;
	}

	&.width-50 {
		width:50%;

		.ui.checkbox {
			width:50%;
			float:left;
		}
	}

	&.width-100 {
		width:100%;
	}


	.ui.checkbox {
		margin-bottom:0.5rem;
	  	width: 100%;
	}
	.ui.space {
		min-width: 20px;
		height: 5.4rem;
	}

}

.filter-row {
	.clearfix();
	overflow:visible;

	&.last {
		.filter-section {
			padding-top: 0px;
		}
	}
}


// CHECOUT WIDGET //

.checkout-price {
	display: table;
	width:100%;
	vertical-align: bottom;
	border-bottom:3px double @color-grey-border;

	padding-bottom:0.5rem;

	p {
		display: table-cell;
		vertical-align: bottom;
		line-height: 1em;
	}
}
.checkout-privacy {
	display: inline-block;
	margin: 11px 0 13px 0;

	@media (max-width: 500px) {
		float: right;
	}
}
.contact-privacy {
	display: inline-block;
	margin: 11px 0 13px 0 !important;
}



// WIDGET RESPONSIVE //

.ui.menu {
	font-size:@font-navigation;
}

.widget.responsive {
	width:90%;
	margin:0 auto;
	.clearfix();
	position: static;
	top:auto;
	display: block;
	overflow:visible;

	.widget-container {
		width:100%;
		.clearfix();
		overflow:visible;
	}

	.dropdown {
		.box-sizing(border-box);
		width:100%;
		border:none;
		border-bottom:1px solid rgba(0,0,0,0.1);
	}

	.input {
		.box-sizing(border-box);
		width:100%;
		input {
			width:100%;
			border:none;
			border-bottom:1px solid rgba(0,0,0,0.1);
		}
	}
}

// DATEPICKER //

.picker,.picker.classic {
	width:400px;
	margin-left:-100px;
}

.picker__holder {
	overflow: hidden;
}
.picker__select--year {
	width: 30%;
}

// ORDER WIDGET OVERVIEW //

.order-widget {
	width:100%;
	background:white;
	height:45px;
	display: block;
	.offset-top();

	.ui.dropdown .menu {
		z-index: 111;
	}
}

.picker.inline-fixed {
	margin-left: 0px;
	z-index: inherit;

	.picker__button--clear {
		display:none;
	}
	.picker__button--close {
		display: none;
	}
	.picker__button--today {
		display: none;
	}

	.picker__select--year {
		height:auto;
	}

	.picker__day--disabled {
		background:lighten(@color-red,40%);
		color:@color-dark;
	}

	.picker__day--disabledafter {
		border: 1px transparent;
		background: rgba(244,123,138,1);
		background: -moz-linear-gradient(-45deg, rgba(244,123,138,1) 0%, rgba(244,123,138,1) 50%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%);
		background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(244,123,138,1)), color-stop(50%, rgba(244,123,138,1)), color-stop(51%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1)));
		background: -webkit-linear-gradient(-45deg, rgba(244,123,138,1) 0%, rgba(244,123,138,1) 50%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%);
		background: -o-linear-gradient(-45deg, rgba(244,123,138,1) 0%, rgba(244,123,138,1) 50%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%);
		background: -ms-linear-gradient(-45deg, rgba(244,123,138,1) 0%, rgba(244,123,138,1) 50%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%);
		background: linear-gradient(155deg, rgba(244,123,138,1) 0%, rgba(244,123,138,1) 50%, rgba(255,255,255,1) 51%, rgba(255,255,255,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f47b8a', endColorstr='#ffffff', GradientType=1 );
	}
	.picker__day--disablefirst {
		border: 1px transparent;
		background: rgba(255,255,255,1);
		background: -moz-linear-gradient(-45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(244,123,138,1) 50%, rgba(244,123,138,1) 100%);
		background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(49%, rgba(255,255,255,1)), color-stop(50%, rgba(244,123,138,1)), color-stop(100%, rgba(244,123,138,1)));
		background: -webkit-linear-gradient(-45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(244,123,138,1) 50%, rgba(244,123,138,1) 100%);
		background: -o-linear-gradient(-45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(244,123,138,1) 50%, rgba(244,123,138,1) 100%);
		background: -ms-linear-gradient(-45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(244,123,138,1) 50%, rgba(244,123,138,1) 100%);
		background: linear-gradient(155deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(244,123,138,1) 50%, rgba(244,123,138,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f47b8a', GradientType=1 );
	}

}
.picker__input.hide {
	display: none;
}


// MAIN PAGE INTRO //




// OBJECT OVERVIEW //

.object-overview-item {
	margin-bottom:2rem;
	table-layout: fixed;

	&.hideObject {
		display:none;
	}
}

.object-overview-image {
	width:25%;
	height:250px;
	background:white;
	float:left;
	// display: table-cell;

}

.object-overview-image-slider {
	display: block;
	position: relative;
	img {
		// height:100%;
		width:100%;
		display:block;
	}
	a {
		display: block;
	}
	&:not(.slick-initialized){
		a {
			display: none;
			&:first-child {
				display:block;
			}
		}
	}
}

.object-overview-image-link {
	display: block;
	height: 100%;
	width: 100%;
}

.object-overview-text {
	width:75%;
	float:left;
	.box-sizing(border-box);
	padding:0.5rem 2rem;
	// border:1px solid green;
	position: relative;
	// display: table-cell;
	height:100%;
	vertical-align: top;
}

.object-overview-text-container {
	max-width: 70%;

	h3 {
		a {
			color:@color-dark;

			&:hover {
				color:@color-red;
			}
		}
	}
}

.object-overview-meta {
	top:1rem;
	position: absolute;
	right:1rem;
	text-align: right;
	max-width:30%;

	a {
		color:lighten(@color-dark,30%);

		&:hover,&.active {
			color:@color-red;
		}
	}
}

.object-overview-price {
	position: absolute;
	right:1rem;
	bottom:0;
	max-width:30%;

	.price {
		text-align: right;
	}
}

.object-info {
	margin-top:3rem;
	max-width:550px;
}


// OBJECT MAP //
.object-map {
	position: relative;
	overflow: hidden;
	margin-bottom: 5rem;

	i.big.basic.icon {
		position: absolute;
		top: 4px;
		right: 28px;
		font-size: 18px;
		margin: 0;
		cursor: pointer;
	}
}

.object-map-map {
	width: 100%;
	min-height: 500px;
}

.object-map-info {
	max-width: 600px;
	min-width: 400px;
	height: 100%;
	overflow: hidden;

    h3 {
      margin-bottom: 5px;
    }

    .map-left {
		float: left;
		width: 40%;
	  	margin-top: 5px;
	}

  	.button {
	  &.primary {
		font-size: 1em;
		padding-left: 0;
		padding-right: 0;
		width: 100%;
		text-align: center;
	  }
	}

    img {
      max-width: 100%;
      height: auto;
    }

  	.price {
	  text-align: right;
	  margin-top: 1px;

	  .big {
		display: inline-block;
		font-size: 38px;
		line-height: 0.9;
	  }
	}

    .map-right {
      margin-left: 42%;
    }
}

.map-row {
	&.last {
		margin-bottom: 2rem;

		.map-left {
			margin-top: 0;
		}
	}
}

.map-overlay {
	position: absolute;
	top: 2rem;
	left: 0rem;
	padding: 0 2rem;
	background: #ffffff;
	width: auto;
	height: 89%;
	max-width: 32%;
	display: none;

	&.show {
		display: block;
	}

	.top-info {
		max-height: 32%;
	}

	.scroll {
		overflow-y: auto;
		height: 68%;
	}
}

// OBJECT DETAIL //

.object-detail-header {
	width:100%;
	height:800px;
	background-color:#e9ddc5;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}
.picker.picker-object-detail {
	width:100%;
	margin-left:0px;
	position: absolute;
	top: 160px;
}

.object-detail-header-image-link {
	display: block;
	height:100%;
	width:100%;
}

.object-detail-header-images-container {
	width:100%;
	height:20%;
	position: absolute;
	bottom:0;
    border-top: 1px solid #ffffff;
    .box-sizing(border-box);

    a {
      outline: 1px solid #ffffff;

      &:first-child {
        border-left: none;
      }
    }
}

.object-detail-header-images {
	width:16.666%;
	height:100%;
	float:left;
}

.object-detail-infobox {
	width:100%;
	background:white;

	&.checkout {
		background:none;
	}
}

.object-detail-subnav {

	width:100%;
	height:58px;
	background:@color-grey-lightest;

	&.guide {
		ul {
			padding:0;
		}

		a {
			&.active {
				color:@color-red;
			}
		}
	}

	ul {
		.clearfix();
		.box-sizing(border-box);
	}

	li {
		float:left;
		line-height: 58px;
		margin-right:1rem;
		a {
			color:@color-grey-light;
			.ultramini;

			&:hover {
				color:@color-red;
			}
		}
	}
}

.object-detail-widget-wrapper {


}

.object-detail-widget {

	* {
		box-sizing: border-box;
	}

	width:@object-detail-widget-width;
	padding:0 1.5rem;
	float:right;
	position: relative;
	background:white;

	&.scroll-to-fixed-fixed {
	}

	.checkout & {
		margin-top:3rem;

		&.scroll-to-fixed-fixed {
			margin-top:0;
		}
	}

	.button.secondary {
		background: #efefef;
		padding-top: 14px;
		padding-bottom: 15px;
	}

}

.object-detail-price {
	width:100%;
	background:@color-dark;
	position: absolute;
	right:0;
	top:-3.625rem;
	z-index: 5;
	padding:0.5rem 1.5rem;
	overflow: visible !important;
	line-height: 1;
	color:white;
	.big;
	.transition(height 0.3s);

	.scroll-to-fixed-fixed & {
		height:58px;
	}
}


.object-detail-widget-text {
	padding:1rem 0 0 1rem;
}


.object-detail-widget-container {
	float:right;

	&.checkout {
		float:left;
		width:100%;
	}
}

.object-detail-icon {
	margin-top:1rem;
	width:16%;
	margin-right:3%;
	float:left;
	line-height: 50px;
	.clearfix();

	i {
		float:left;
	}

	> div {
		float:left;
	}

	&.last {
		margin-bottom: 1rem;
		line-height: 1;
		width:19%;

		div {
			position: relative;
			top:-4px;
		}
	}
	&.first {
		width: auto;
	}
}


i.bed {
	width:50px;
	height: 50px;
	background:url('../img/icon-bed.png') left center no-repeat;
	background-size:85%;
}

i.door {
	width:50px;
	height: 50px;
	background:url('../img/icon-door.png') center center no-repeat;
	background-size:70%;
}

i.big.basic.icon {
	margin-right:0.2rem;
}


.object-detail-content-wrapper {
	.box-sizing(border-box);
	padding:2rem;
	width:60%;
	margin-top:3rem;
	// max-with:850px;
	background:white;
	height:100%;
	float:left;

	&.contact {
		margin-top:3rem !important;
	}
}

.object-detail-content-section {
	border-bottom:1px solid @color-grey-border;
	padding-bottom:1rem;
	margin-bottom:1rem;
	//.clearfix();
	.micro-clearfix();
	h4 {
		width:25%;
		float:left;
		font-size:@font-mini;
		font-weight: bold;

        i{
            display: none;
        }
	}

	.detail-content {
		width:75%;
		float:left;

        h4 {
            float: none;
            width: 100%;
        }
	}
}

[class^="icomoon-"], [class*=" icomoon-"] {
  width: 24px;
  display: inline-block;
}

ul {

	&.detail-content {
		margin:0;
		padding:0;
		.clearfix();

		li {
			float:left;
			width:50%;
			margin-bottom:0.5rem;
		}
	}
}

.detail-prices {
	text-align: left;
	width:100%;
	border:1px solid @color-grey-lightest;
	margin-bottom:2rem;
	th {
		background:@color-grey-lightest;
		width:40%;
		text-align: left;
	}

	td, th {
		padding:0.5rem;
		border-right:1px solid @color-grey-lightest;
		border-bottom:1px solid @color-grey-lightest;

		&.price {
			text-align: right;
		}
	}
}

.gallerybox {
	position: fixed;
	z-index: 150;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.9);
	&.hide {
		display:none;
	}



}
.gallerybox-slider {
	display: block !important;
	left: 00%;
	top: 00%;
	width: 100%;
	height: 90%;
	padding-top: 20px;
	padding-bottom: 20px;

	img {
		left: 50%;
		top: 50%;
		position: relative;
		-moz-transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		max-width: 80%;
		height: auto;
	}


}
.ui.dimmer.dimmer-gallery {
	.content {
		display: block;
	}
}

.ui.dimmer {
    > .content {
      padding-bottom: 0;

      	> div {
            &.close-map {
                display: block;
                background: @color-red;
            }
        }

    }

	&.dimmer-map {

		> .content {
			width: 80%;
			height: 80%;
			position: relative;
			top: 10%;
			left: 10%;

			.dimmer-map-map {
				display: block;
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
}

.close-map {
  position: absolute;
  height: 44px;
  width: 44px;
  right: 20px;
  top: 20px;
  z-index: 100;
  cursor: pointer;

  i {
    font-size: 36px;
    padding-top: 3px;
  }
}

.slick-track, .slick-list {
	height:100%;
}

.gallerybox-slider-image {
	height:100%;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
}

.gallerybox-infobar {
	position: absolute;
	left: 0%;
	bottom: 10%;
	padding: 1rem;
	width: 100%;
	margin-bottom: 0;
	background: black;
	text-align: left;

}

.gallerybox-thumbs {
	position: absolute;
	left: 0%;
	bottom: 0%;
	height: 10%;
	width: 100%;
	margin-bottom:0;
	background: black;

	a {
		img {
			.opacity(0.5);
		}

		&.slick-active, &:hover {
			img {
				.opacity(1);
			}
		}
	}
	img {

		// margin-left: auto;
		// margin-right: auto;
		// width:200px;
	}
}

.close-gallery {
	position: absolute;
	top:5%;
	right:5%;
	color:white;

	&:hover {
		color:@color-red;
	}
}

// HTML GALLERY CLASS //

.gallery-show {

	overflow-y:hidden;

}


// CONTENT SITES //

.content-site-header {
	height:350px;
	background:green;
}

.full-content {
	width:100%;
	// background:white;
	min-height: 500px;

	h4 {
		max-width: 650px;
		margin-top:2rem;
	}
}

.content-image-sidebar {
	width:30%;
	float:left;
	//height:500px;
	margin-top:3rem;

	img {
		width:100%;
		margin:0;
		float:left;
		margin-bottom: 1px;
	}
}

.white-bg {
	.content-image-sidebar {
		width: 40%;
	}
}




// SIDEBAR //

.ui.active.sidebar {
	overflow-y:scroll;
}

.sidebar-close {
	display:block;
	margin: 5% 5% 5% auto;
	text-align: right;

}




// FORM ELEMENTS //

input {
	.transition(all 0.3s);
}

::-webkit-input-placeholder { /* WebKit browsers */
	font-weight:normal;
	font-family:'FrutigerNeue1450W01-Boo_1196290';
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	font-weight:normal;
	font-family:'FrutigerNeue1450W01-Boo_1196290';
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	font-weight:normal;
	font-family:'FrutigerNeue1450W01-Boo_1196290';
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
	font-weight:normal;
	font-family:'FrutigerNeue1450W01-Boo_1196290';
}


.ui.input {
	input {
		.border-radius(0);
	}
}

.ui.form {
	input {
		.border-radius(0) !important;
	}

	.field {
		&.size-25 {
			width:20%;
			margin-right:5%;
			float:left;
			clear:none;
		}

		&.size-75 {
			width:75%;
			float:left;
			clear:none;
		}
	}
}

.field {
	&.sidebar {
		float:left;
		clear: none !important;
		margin-bottom:0 !important;

		&.size-50 {
			width:50%;
		}

		&.size-100 {
			width: 100%;
			float: none;

			&:first-child {
				margin-bottom: 10px !important;
			}
		}


		.checkbox {
			margin-left:1px !important;
		}
	}
}

input {
	.border-radius(0) !important;

	&.picker__input {
		width:0;
	}
}

input[type=submit] {
	background:@color-green;
	padding:11px 2rem 13px 2rem;
	border: none;
	color:white;
    height: auto;

	&:hover {
		background:lighten(@color-green,10%);
	}
}

@-moz-document url-prefix() {
	input[type=submit] {
		padding-bottom: 12px;
	}
}

.wysiwyg-output{
	ul{
		list-style-type: disc;
	}
	p {
		font-size: inherit;
	}
}

.test {
	.border-radius(25%);
	margin:200px auto 0;
	width:200px;
	height:200px;
	background:@color-red;
	position:relative;

	.dot-left {
		position: absolute;
		left:20%;
		top:30%;
		width:30px;
		height:30px;
		background:white;
		.border-radius(100%);
	}

	.dot-right {
		position: absolute;
		right:20%;
		top:30%;
		width:30px;
		height:30px;
		background:white;
		.border-radius(100%);
	}
}

/* IE 11 fixes*/
@media all and (-ms-high-contrast:none) {
	.widget .input.ui input {
		line-height: 0;
	}
}

.browser-ie,
.browser-ie10,
.browser-ie11 {
	.widget .input.ui input {
		line-height: 0;
	}
}

.browser-ie {
	&.ie9 {
		.meta-navigation .dropdown.ui .menu li {
			text-align: left;
		}
		.dropdown.ui .menu .item {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
		}
	}

}

.browser-ie10,
.browser-ie11 {

}

.lowie {
	width: 80%;
	height: 80%;
	padding: 10%;
	font-size: 2em;
	background-color: lightgrey;
	position: absolute;
	z-index: 9999;
	display: none;
}

.lt-ie9 {
	.lowie {
		display: block;
	}
}
