@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon.eot');
	src:url('../fonts/icomoon.eot?#iefix-aw3dgz') format('embedded-opentype'),
		url('../fonts/icomoon.woff') format('woff'),
		url('../fonts/icomoon.ttf') format('truetype'),
		url('../fonts/icomoon.svg#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icomoon-"], [class*=" icomoon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icomoon-wifi:before {
  content: "\e600";
}

.icomoon-pool:before {
	  content: "\e601";
}

.icomoon-telephone:before {
  content: "\e602";
}
.icomoon-dishwashing:before {
  content: "\e603";
}
.icomoon-washingmachine:before {
  content: "\e604";
}
.icomoon-pets:before {
  content: "\e605";
}
.icomoon-airconditioning:before {
  content: "\e606";
}
.icomoon-heating:before {
  content: "\e607";
}
.icomoon-wheelchair:before {
  content: "\e608";
}
.icomoon-television:before {
  content: "\e609";
}

.icomoon-petsnot:before {
	content: "\e610";
}