// RESONSIVE TYPO STEPS
@media (max-width: 1000px) {
	p {
		font-size: 1rem;
	}

	h1 {
		font-size: 2.5rem;
	}

	h2 {
		font-size: 2rem;
	}
}

@media (max-width: 500px) {

	h1 {
		font-size: 2rem;
	}

	h2 {
		font-size: 2rem;
	}
}

@media (max-width: 1200px) {
	html {
		font-size: 100%;
	}

	.meta-navigation {
		.icon-navigation {
			font-size: 1.5rem;

			span {
				padding: 1rem 0.8rem;
			}
		}
	}
}

@media (max-width: 1235px) {
	header {
		ul {
			padding-left: 1rem;
			li {
				margin-right: 1rem;
			}
		}
	}
}


@media (min-width: 1250px) {
	html {
		font-size: 100%;
	}
}

@media (min-width: 1001px) and (max-width: 1320px) {
	input[type=submit] {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.widget {
		input[type=text] {
			max-width: 145px;
		}
		.ui {

			&.selection {
				&.dropdown {
					min-width: 110px;
					max-width: 110px;

					&.first {
						min-width: 110px;
						max-width: 110px;
					}

					&.second {
						min-width: 90px;
						max-width: 90px;
					}
				}
			}

			&.input {
				input {
					max-width: 145px;
				}
			}
		}

		&.object-detail {
			.ui {
				&.selection {
					&.dropdown {
						min-width: 120px;
						max-width: 100%;

						&.first {
							min-width: 120px;
							max-width: 100%;
						}
					}
				}

				&.input {
					input {
						max-width: 100%;
					}
				}
			}
		}
	}

	.object-detail-widget {
		width: 33%;
	}

	.object-detail-icon {
		div {
			font-size: 18px;
		}

		&.last {
			div {
				margin-top: 8px;
			}
		}
	}

	i.big.basic.icon {
		font-size: 30px;
		margin-top: 8px;
	}

	i {
		&.door,
		&.bed {
			width: 35px;
			height: 35px;
			margin-top: 8px;
		}
	}
}

// NAVIGATION RESPONSIVE //
/*
@media (min-width: 1091px) {

	.ui.active.sidebar {
		display: none;
	}
}
*/
@media (max-width: 1119px) {
	.meta-bar ul {
		float: left !important;
		margin-top: 10px;
	}
}

@media (max-width: 1000px) {

	body {
		// padding-right:30px;
	}

	header {
		height: 61px;
	}

	input[type=submit] {
		background: @color-green;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.wrapped {
		width: 100%;
	}

	.content {
		padding: 0;
	}

	.desktop {
		display: none;
	}

	.mobile {
		display: block !important;
	}

	.slider {
		&.frontpage {
			height: 600px;
			.imageslide {
				height: 600px;
			}
			.slick-prev, .slick-next {
				top:  57%;
			}
		}
	}

	.frontpage-stoerer-left {
		top:@header-height;
		width: 150px;
		height: 150px;

		a {
			transform: translate(-26%, 63%) rotate(-45deg);
			padding: 0.1em;

			&.singleline {
				padding: 0.8rem;
			}

		}
		.firstline {
			font-size: 0.8rem;
		}
		.secondline {
			font-size: 1.3rem;
		}
	}

	.frontpage-layer {
		height: 600px;
	}

	.frontpage-cta {
		display: inline !important;
		bottom: -53%;
	}

	// MOBILE BOOKING WIDGET //
	.widget-container.floatleft {
		//width:90%;
		//margin:0 2.5%;
	}

	i.icon.search {
		margin: 0;
	}

	#objNumber {
		width: 183px;
	}

	.widget.responsive {
		margin-top: 2rem;

		.widget-additional-input {
			position: static;
		}

		.filter-section {
			&.width-25, &.width-50 {
				width: 100%;
			}

			&.width-50 {
				.ui.checkbox {
					width: 100%;
				}
			}
		}

		.ui.selection.dropdown {
			border: 1px solid @color-grey-border;
			margin-bottom: 1rem;
			width: 100%;
		}

		.ui.checkbox label {
			font-size: 16px;
		}

		.input {
			input {
				border: 1px solid @color-grey-border;
				margin-bottom: 1rem;
			}
		}

	}

	.widget.object-detail.checkout {
		width: 100%;
		padding: 0 4%;
	}

	// SUBNAVIGATIONS //
	.object-detail-subnav {
		width: 100%;
		height: auto;
		background: @color-grey-lightest;

		&.guide {
			ul {
				padding: 0 1rem;
			}

			a {
				&.active {
					color: @color-red;
				}
			}
			li {
				&.first {
					display: none;
				}
				width: 33.3333%;
				float: left;
				margin: 0;
				padding: 6px 0 7px 0;
				line-height: 1;
				display: list-item;
			}
		}

		ul {
			.clearfix();
		}

		li {
			float: left;
			line-height: 58px;
			margin-right: 1rem;
			width: 100%;
			display: none;

			a {
				color: @color-grey-light;
				.ultramini;

				&:hover {
					color: @color-red;
				}
			}
		}
	}

	// OBJECT DETAIL //
	.object-detail-header {
		height: 400px;
		background-size: cover;
	}

	.object-detail-header-images-container {
		display: none;
	}

	.object-detail-widget-container {
		float: none;
		padding: 0 1rem;
	}

	.object-detail-widget {
		width: 100%;
		padding: 0;

		input.picker__input {
			min-width: 0;
		}
	}

	.object-detail-icon, .object-detail-icon.last {
		width: 100%;
		margin-right: 0;
	}

	.object-detail-content-wrapper {
		width: 100%;
		padding: 2rem 1rem;
		margin-top: 0;
	}

	.object-detail-content-section {
		padding-bottom: 0;

		.wysiwyg-output {
			padding-bottom: 2rem;
		}

		h4, .detail-content {
			width: 100%;
		}

		h4 {
			cursor: pointer;

			i {
				float: right;
				color: #000000;
				-webkit-transform: rotate(90deg);
				-ms-transform: rotate(90deg);
				transform: rotate(90deg);
				display: block;
			}
		}
		.collapsing {
			&.mini,
			&.detail-content {
				display: none;
			}
		}

		ul.detail-content {
			li {
				width: 100%;
			}
		}

		&.short {
			padding-bottom: 1rem;

			.mini,
			.detail-content {
				display: block;
			}

			h4 {
				i {
					-webkit-transform: rotate(0deg);
					-ms-transform: rotate(0deg);
					transform: rotate(0deg);
				}
			}
		}

	}

	// OBJECT OVERVIEW //
	.object-overview-widget {
		display: none;
	}

	.contentfirst {
		h1 {
			margin-top: 5rem;
		}

		padding: 2rem 0;
	}

	.pager {
		.first-entry, .last-entry {
			display: none;
		}
	}

	.object-overview-item {

		&.padding {
			padding: 0;
		}
	}

	.object-overview-image {
		width: 35%;
	}

	.object-overview-image-slider {
		img {
			height: auto;
			max-width: 100%;
			width: auto;
		}
	}

	.object-overview-text {
		width: 65%;
		padding: 0.5rem 1rem;
	}

	.object-overview-text-container {
		width: 100%;
		max-width: 100%;
	}

	.object-overview-meta {
		position: static;
		max-width: 100%;
		text-align: left;
		width: 100%;

		a {

		}
	}

	.meta-bar {
		margin: 0;
		ul {
			float: none !important;
			margin-top: 1rem;
			.clearfix();

			li {
				outline: 1px solid #f6f6f6;
				width: 50%;
				a {
					display: block;
					font-size: 12px;
					border: none;
				}
			}
		}
	}

	.object-info {
		margin-top: 0;
		display: none;
		&.object-info-important {
			display: block
		}
	}

	.object-overview-price {
		position: static;
		width: 100%;
		max-width: 100%;
		margin: 1rem 0;

		.price {
			float: left;
		}
	}

	.white-bg .content-image-sidebar,
	.content-image-sidebar {
		width: 100%;
	}

	.ui.form .field {
		&.size-75, &.size-25 {
			width: 100%;
			margin-right: 0;
		}
	}

	// GALLERY MOBILE
	.gallerybox-thumbs {
		display: none !important;
	}
	.gallerybox-infobar {
		bottom: 0%;
		span {
			float:left;
		}
	}

	.gallerybox-slider {
		.gallerybox-slider-image {
			background-size: contain !important;
		}
	}

}

@media (max-width: 1090px) {
	.main-navigation {
		display: none;
	}

	.meta-navigation {
		display: none;
	}

	.mobile-navigation {
		display: block;

		ul li {
			height: 56px;
			line-height: 56px;
		}
	}
	.order-widget {
		margin-top: 3.5rem;
	}

	header {
		background: @color-red;

		h1.image {
			img {
				height: 40px;
			}
		}
	}
}


@media (min-width: 500px) and (max-width: 670px) {
	.object-overview-price .price {
		margin-top: 8px;
	}

	.button.primary {
		padding: 0.8rem 0.6rem;
	}


	.big {
		font-size: 24px;
	}
}

@media (max-width: 500px) {
	.big {
		font-size: 24px;
	}

	.center-button {
		text-align: center;

		.button.primary {
			float: none!important;
			margin-top: 1em;
		}
	}

	.object-overview-price {
		.clearfix();

		.price {
			text-align: left;
			margin-top: 1em;
			float: none;
		}
	}

	.object-overview-image {
		width: 100%;
		height: auto;
	}

	.object-overview-text {
		width: 100%;
		padding: 0.5rem 1rem;
	}

	.object-overview-image-slider {
		img {
			width: 100%;
		}
	}

	.slider {
		&.frontpage {
			height: 400px;
			.imageslide {
				height: 400px;
			}
		}

	}

	.frontpage-layer {
		height: 400px;
	}
}

@media (min-width: 1000px) {
	i.icon.search {
		display: none;
	}
}
// GALLERY OVERRIDES FOR NOT SO HIGH SCREENS

// @media (min-height: 600px) {
// 	.gallerybox-slider {
// 		top:5%;
// 	}
// }

// @media (min-height: 800px) {
// 	.gallerybox-slider {
// 		top:10%;
// 	}
// }

// @media (min-height: 1000px) {
// 	.gallerybox-slider {
// 		top:20%;
// 	}
// }
