  /////////////
 // GLOBALS //
/////////////

// LISTS //

ul {
	list-style:none;
}


// LINKS //

a {
	color:@color-red;
	text-decoration: none;
	.transition(all 0.3s);

	&.active, .active & {
		color:black;
	}

	nav & {
		color:white;

		&:hover {
			color:@color-red;
		}
	}

	&.text {
		color:black;
	}

	&:hover {
		color:lighten(@color-red, 20%);
	}
}


// WIDTHS //

.full-width() {
	width:100%;
	padding:0 2rem;
	box-sizing: border-box;
}

.wrapped() {
	width:90%;
	max-width:@max-width;
}

.wrapped {
	.wrapped();
	margin:0 auto;

	&.padding {
		padding:2rem;
		background:white;
	}

	&.white-bg {
		background: #ffffff;
		margin-top: 3rem;

		.object-detail-content-wrapper,
		.content-image-sidebar {
			margin-top: 0;
		}
	}
}

.offset-top() {
	margin-top:@header-height-full;
}

.floatright {
	float:right !important;
}

.floatleft {
	float:left !important;
}

.textalignright {
	text-align: right;
}



// FONTS //

.serif {
	font-family:'Bembo W01', Times !important;
	font-weight: normal;
}

.center {
	text-align: center;
}

.strong {
	font-family:'FrutigerNeue1450W01-Bol_1196308';
}


// HEADLINES //

// h1,h2,h3,h4 {
// 	font-family:'FrutigerNeue1450W01-Bol_1196308';
// }

h1 {
	font-size:3.5rem;
	font-weight: normal;
	margin:0;
}

h2 {
	font-size:3.5rem;
	margin:0;
	font-weight: normal;

	&.checkout {
		margin-bottom: 0.25em;
	}
}

h3 {
	font-size:2.5rem;
	margin:0;
	font-weight:normal;
}

h4 {
	font-size:@font-base;
	font-weight:normal;
	margin:0 0 1rem 0;
}

p {
	font-size:@font-small;
	margin:0 0 0.5rem 0;
	line-height: 1.4em;
	max-width:650px;

	&.fullwidth {
		max-width: inherit;
	}
}
quote {
	font-style:italic;
}


// FONT SIZE HELPERS

.small {
	font-size:@font-small;
}

.medium { 
	font-size:@font-medium
}

.mini {
	font-size:@font-mini;
	p {
		font-size:@font-mini;
	}
}

.ultramini {
	font-size:@font-ultramini;
}

.big {
	font-size:@font-big;
}

.base {
	font-size:@font-base;
}


.mobile {
	display: none !important;
}


.secondary {
	color:lighten(@color-dark,30%);
}





.button {
	display: inline-block;
	padding:0.8rem 2rem;
	border: none;
	color:white;
	font-size:@font-base;

	&.primary {
		background:@color-green;

			&:hover {
				color:white;
			}
	}

	&.secondary {
		color:@color-dark;
		font-size:@font-mini;
		padding:0.8rem 1rem;

		&:hover {
			background:none;
			color:@color-red;
		}

	}

	//&:hover {
	//	background:lighten(@color-green,10%);
	//}
}


.table {
	display:table;
}

.block {
	display: block;
}

.relative {
	position: relative;
}

.section {
	border-bottom:1px solid @color-grey-border;
	.clearfix();
	padding:1.5rem 0;
}



// SLICK OVERRIDES //

.slick-next, .slick-prev {
	z-index: 20;
}

.slick-next {
	right:25px;
}

.slick-prev {
	left:25px;
}

.slick-dots {
	bottom:45px;
	z-index: 10;

	li {
		margin:0 0.5rem 0 0;

		&.slick-active {
			button {
				background:@color-red;
			}
		}

		button {
			background:white;
			height:12px;
			width:12px;

			&:after, &:before {
				content:'';
			}
		}

	}
}

.ui.dimmer {
	z-index: 998;
}


.white {
	color: white;
}
